<route>
{
  "meta": {
    "permission": [
      "equipements.view_equipement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <!-- Lista de equipos -->
    <i-table
      :title="$tc('equipment', 2)"
      :headers="headers"
      :otherPermission="equipementBtn.dial"
      app="equipements.equipement"
      api="equipment"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
      :otherBtn="equipementBtn.maintenance"
    >
      <!-- Botón para acceder a mantenimiento de equipo -->
      <template v-slot:otherBtn="{ item }">
        <i-btn
          :title="$tc('equipment_maintenance', 1)"
          outlined
          classes="mx-1"
          icon="fa-calendar"
          color="secondary"
          @click="changeRouteMaintenance(item)"
        />
      </template>

      <!-- Descripción del equipo -->
      <template v-slot:item.description="{ item }">
        <span v-html="item.description"></span>
      </template>

      <!-- Forma de adquisición del equipo -->
      <template v-slot:item.mode="{ item }">
        <span v-text="getModeText(item.mode)"></span>
      </template>

      <!-- Fechas de renta o de compra del equipo -->
      <template v-slot:item.bought_date_and_rent_date="{ item }">
        <span v-text="getDateText(item)"></span>
      </template>

      <!-- Recurrencia del mantenimiento de equipo -->
      <template v-slot:item.recurrent="{ item }">
        <v-col>
          <i-btn
            v-if="!item.recurrent"
            :title="$tc('regular')"
            class="mx-1"
            outlined
            color="red"
            icon="fa-times"
          />
          <span v-else v-text="item.type_recurrent"></span>
        </v-col>
      </template>
    </i-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    headers() {
      return [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('model'), value: 'modelName' },
        { text: this.$t('serial'), value: 'serial' },
        { text: this.$t('mode'), value: 'mode' },
        {
          text: this.$t('bought_date_and_rent_date'),
          value: 'bought_date_and_rent_date'
        },
        {
          text: this.$tc('department', 1),
          value: 'production_departament.name'
        },
        { text: this.$tc('recurrent', 1), value: 'recurrent' },
        {
          text:
            this.equipementBtn.delete ||
            this.equipementBtn.edit ||
            this.equipementBtn.maintenance
              ? this.$t('action')
              : '',
          value:
            this.equipementBtn.delete ||
            this.equipementBtn.edit ||
            this.equipementBtn.maintenance
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),

    /**
     * equipementBtn
     * Computado para validar permisos para:
     * Eliminar y editar equipos, agregar mantenimiento de equipo, ver marca y
     * modelo y ver departamento de producción.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    equipementBtn() {
      return {
        delete:
          this.getPermissions([`equipements.delete_equipement`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`equipements.change_equipement`]) ||
          this.isAdmin,
        maintenance:
          this.getPermissions([`equipements.add_maintenance`]) || this.isAdmin,
        dial:
          (this.getPermissions([`equipements.view_brandmodel`]) &&
            this.getPermissions([`base_config.view_productiondepartment`])) ||
          this.isAdmin
      }
    }
  },
  methods: {
    /**
     * changeRoute
     * Método para cambiar la ruta al formulario de equipo.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeRoute(item) {
      this.$router.push({
        name: 'equipment-pk',
        params: {
          pk: this.codification({ code: item.pk })
        }
      })
    },

    /**
     * changeRouteMaintenance
     * Método para cambiar la ruta a la vista del formulario de mantenimiento.
     * Si se trata de una creación de mantenimiento, inicializa el parámetro
     * correspondiente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeRouteMaintenance(item) {
      this.$router.push({
        name: 'maintenance',
        params: {
          pk: this.codification({ code: 'create' }),
          equipment: JSON.stringify(item)
        }
      })
    },

    /**
     * getModeText
     * Método para obtener el texto correspondiente al modo de adquisición.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getModeText(mode) {
      return mode === 'buy'
        ? this.$t('buy')
        : mode === 'rent'
        ? this.$t('rent')
        : ''
    },

    /**
     * getDateText
     * Método para formatear las fechas de compra o renta.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getDateText(item) {
      return item.rent_start_date && item.rent_end_date
        ? `${this.dateFormat(item.rent_start_date)} / ${this.dateFormat(
            item.rent_end_date
          )}`
        : this.dateFormat(item.bought_date)
    }
  }
}
</script>
